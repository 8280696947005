<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.allotNo" class="input" placeholder="调拨单号" clearable></el-input>
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="校色前编码" clearable></el-input>
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="校色前条码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="importBatch = true">导入</el-button>
    </el-row>

    <el-table :data="dataList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="poDoc" label="入暂存订单号"></el-table-column>
      <el-table-column align="center" prop="allotNo" label="调拨单号" width="200"></el-table-column>
      <el-table-column align="center" prop="materialCode" label="校色前编码"></el-table-column>
      <el-table-column align="center" prop="assetSn" label="校色前条码" width="200"></el-table-column>
      <el-table-column align="center" prop="agingStartTime" label="入暂存时间" width="200"></el-table-column>
      <el-table-column align="center" prop="orderType" label="订单类型"></el-table-column>
      <el-table-column align="center" prop="count" label="数量"></el-table-column>
      <el-table-column align="center" prop="lenovoCode" label="入校色库订单号" width="200"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="200"></el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

    <el-dialog title="调拨数据导入" :visible.sync="importBatch" :close-on-click-modal="false" width="800px" :before-close="importClose">
      <ImportComponent template-url="template/colorCalibrationBefore.xlsx" upload-url="colorCalibrationBefore/importBatch"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importClose">关闭</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import ImportComponent from "@/components/ImportComponent.vue";

export default {
  name: "ColorCalibrationBefore",
  components: {ImportComponent},
  data() {
    return {
      queryInfo: {
        allotNo: '',
        materialCode: '',
        assetSn: '',
        pageNo: 1
      },
      dataList: [],
      total: 0,
      pageSize: 0,
      importBatch: false,
      loading: false
    }
  },
  created() {
    this.search();
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('colorCalibrationBefore/queryList', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.dataList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    importClose() {
      this.search();
      this.importBatch = false;
    },
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>